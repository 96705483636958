/* eslint-disable  */
import React, {useRef, useCallback, useMemo, useState,useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import axios from "axios";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { Delete, Edit ,Inventory} from '@mui/icons-material';

import { Link, useLocation } from "react-router-dom";
const Users = () => {

  const states=[{heading:'InActive',value:0},{heading:'Active',value:1}];
  
  let dbData = JSON.parse(localStorage.getItem("dbData"));
      const [posts, setPosts] = useState([]);
  
   
      const cat = useLocation().search
      let user = JSON.parse(localStorage.getItem("user"));
      let tableFlag = (localStorage.getItem("tableflag"));
      console.log("tableFlag",tableFlag,typeof tableFlag);
     
        const [flag, setFlag] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [index, setIndex] = useState(-2);
  useEffect(() => {
    const fetchData = async () => {
      let data;
      try {

        const res = await axios.get(`${process.env.REACT_APP_URL}/api/auth/getusers`, { withCredentials: true });
        data=[...res.data];

    
        console.log("data67",data);
       
        setTableData(data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
   
  }, []);

  useEffect(() => {
    console.log("tableData94",tableData);
  
 
  }, [tableData]);
  useEffect(() => {
    const updateData = async () => {
      if(flag===true){
      console.log("index59",index,tableFlag);
      tableData[index].tableFlag=tableFlag;
     
        console.log("tableData[index].source_objectid",tableData[index].source_objectid);
        await axios.post(`${process.env.REACT_APP_URL}/api/auth/updateuser`, tableData[index], { withCredentials: true })
  
     
      
  };
}
 updateData();
  }, [tableData]);
  const handleCreateNewRow = (values) => {
    tableData.push(values);
    setTableData([...tableData]);
  };
console.log("process.env.REACT_APP_NOT_SECRET_CODE",process.env.NODE_ENV,process.env.REACT_APP_URL);
  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      console.log("row.index,values,row,tableData[row.index]5",row.index,values,row,tableData[row.index])
      tableData[row.index].password = values.password;
      tableData[row.index].is_active = values.is_active;
      tableData[row.index].username = values.username;

      // tableData[row.index]=values;
      await setIndex(row.index);
      setFlag(true);
      //send/receive api updates here, then refetch or update local table data for re-render
      setTableData([...tableData]);
    
    
      exitEditingMode(); //required to exit editing mode and close modal
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    // (row) => {
    //   if (
    //     !confirm(`Are you sure you want to delete ${row.getValue('firstName')}`)
    //   ) {
    //     return;
    //   }
    //   //send api delete request here, then refetch or update local table data for re-render
    //   tableData.splice(row.index, 1);
    //   setTableData([...tableData]);
    // },
    // [tableData],
    (row) => {
       
    localStorage.setItem("dbData", JSON.stringify(posts[row.index]));
    localStorage.setItem("dbflag", 'yes'); 
  
    },
    [posts],
  );

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid =
            cell.column.id === 'email'
              ? validateEmail(event.target.value)
              : cell.column.id === 'age'
              ? validateAge(+event.target.value)
              : validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors],
  );

  const columns = useMemo(
    () => [
   
      {
        accessorKey: 'user_id',
        header: 'User Id',
        enableEditing: false,
        //size: 40,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'username',
        header: 'Username',
        enableEditing: true,
        //size: 20,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
         
        }),
      },
      {
        accessorKey: 'password',
        header: 'Password(encyrpted)',
        enableEditing: true,
        //size: 20,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
         
        }),
      },
      {
        accessorKey: 'is_active',
        header: 'Active',
        required:true,
        muiTableBodyCellEditTextFieldProps: {
          select: true, //change to select for a dropdown
          children: states.map((status) => (
            <MenuItem key="is_active78" value={status.value}>
              {status.heading}
            </MenuItem>
          )),
        },
      },
      // {
      //   accessorKey: 'username',
      //   header: 'Tested By',
      //   enableEditing: false,
      //   //size: 20,
      //   muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
      //     ...getCommonEditTextFieldProps(cell),
         
      //   }),
      // },
     
    ],
    [getCommonEditTextFieldProps],
  );

  return (
    <div style={{padding:"17px"}}>
          <br/> 
           <h3>View/Modify Users</h3> 
           <br/> 
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            //size: 120,
          },
        }}
        renderTopToolbarCustomActions={() => (
          <>
        

          </>
        )}
        columns={columns}
        data={tableData}
        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '5rem',marginLeft:"10%" }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            {/* <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Inventory />
              </IconButton>
            </Tooltip> */}
          </Box>
        )}
        positionActionsColumn="last"
        // renderTopToolbarCustomActions={() => (
        //   <Button
        //     color="secondary"
        //     onClick={() => setCreateModalOpen(true)}
        //     variant="contained"
        //   >
        //     Create New Account
        //   </Button>
        // )}
      />
      <CreateNewAccountModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
      />
    </div>
  );
};

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit }) => {
  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {}),
  );

  const handleSubmit = () => {
    //put your validation logic here
    onSubmit(values);
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Create New Account</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            {columns.map((column) => (
              <TextField
                key={column.accessorKey}
                label={column.header}
                name={column.accessorKey}
                onChange={(e) =>
                  setValues({ ...values, [e.target.name]: e.target.value })
                }
              />
            ))}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          Create New Account
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
const validateAge = (age) => age >= 18 && age <= 50;

export default Users;