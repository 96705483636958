/*!

=========================================================
* Migration Utility Application - v1.10.0
=========================================================

* Product Page: https://www.pascalinesoft.com/product/material-dashboard-react
* Copyright 2021 Saad Hassan Shah (https://www.pascalinesoft.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by saad hassan

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import DBConnect from "pages/DBConnect";
// core components
import Admin from "layouts/Admin.js";
import RTL from "layouts/RTL.js";
import DBConnectParent from "pages/DBConnectParent";
import "assets/css/material-dashboard-react.css?v=1.10.0";
import Login from "layouts/Login";
import Register from "pages/Register";
import TestingResultsParent from "pages/TestingResultsParent";
import DeveloperResultsParent from "pages/DeveloperResultsParent";
import AdminResultsParent from "pages/AdminResultsParent";
import AssignObjectsParent from "pages/AssignObjectsParent";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/admin" component={Admin} />
      <Route path="/rtl" component={RTL} />
      <Route path="/dbconnect" component={DBConnectParent} />
      <Route path="/login" component={Login} />
      <Route path="/register" component={Register} />
      <Route path="/testing" component={TestingResultsParent} />
      <Route path="/developer" component={DeveloperResultsParent} />
      <Route path="/adminresults" component={AdminResultsParent} />
      <Route path="/assignobjects" component={AssignObjectsParent} />
      <Redirect from="/" to="/login" />
    </Switch>
  </BrowserRouter>,
  
  document.getElementById("root")
);
