import React,{useState} from 'react';
import MaterialReactTable from 'material-react-table';
import NotificationReact from './NotificationReact';
import axios from "axios";
//defining columns outside of the component is fine, is stable
const columns = [
 
    {
      accessorKey: 'schema_name',
      header: 'Schema Name',
      enableEditing: false,
      //size: 40,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      }),
    },
    {
      accessorKey: 'object_name',
      header: 'Object Name',
      enableEditing: false,
      //size: 20,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
       
      }),
    },
    {
      accessorKey: 'object_type_id',
      header: 'object type id',
      enableEditing: false,
      //size: 20,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
       
      }),
    },
    {
      accessorKey: 'object_type',
      header: 'object type',
      enableEditing: false,
      //size: 20,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
       
      }),
    },
];




const AssignObject = () => {
  const buttonRef = React.useRef(null);
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("");
  const [text, setText] = useState("");
 
  const [tableData, setTableData] = React.useState([]);
  const [developerData, setDeveloperData] = React.useState([]);
  const [testerData, setTesterData] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [flag, setFlag] = React.useState(false);
  let user = JSON.parse(localStorage.getItem("user"));
  let assignObjectFlag = (localStorage.getItem("assignObjectFlag"));
  console.log("assignObjectFlag",assignObjectFlag)
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        let res;
        if(assignObjectFlag==="source"){
         res = await axios.get(`${process.env.REACT_APP_URL}/api/testresults/${dbData.Project_Id}`, { withCredentials: true });
        }
        if(assignObjectFlag==="target"){
           res = await axios.get(`${process.env.REACT_APP_URL}/api/testresults/target/${dbData.Project_Id}`, { withCredentials: true });
          }
        let data=[...res.data];
     
        for (let i=0;i<data.length;i++){
          data[i].Project_Name=dbData.Project_Name;
                    data[i].user_id=user.user_id;
                 
                 if(data[i].object_type_id === 1){
                  console.log("data[i].object_type_id7 tables",data[i].object_type_id );
            data[i].object_type="tables"; 
          }
          if(data[i].object_type_id === 2){
            console.log("data[i].object_type_id7 views",data[i].object_type_id );
            data[i].object_type="views"; 
          }
          if(data[i].object_type_id === 3){
            console.log("data[i].object_type_id7 functions",data[i].object_type_id );
            data[i].object_type="functions"; 
                    }
          if(data[i].object_type_id === 4){
            console.log("data[i].object_type_id7 procedures",data[i].object_type_id );
            data[i].object_type="procedures";
        }
     
      }
      console.log("data58",data);
        setTableData(data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
    const fetchDeveloper = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_URL}/api/assignobjects/developer/${dbData.Project_Id}`, { withCredentials: true });
        let data=[...res.data];
    
    
      console.log("data57",data);
        setDeveloperData(data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchDeveloper();
    const fetchTester = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_URL}/api/assignobjects/tester/${dbData.Project_Id}`, { withCredentials: true });
        let data=[...res.data];
        console.log("data56",data);
      
       
        setTesterData(data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchTester();
   
  }, []);
  // React.useEffect(() => {
    const updateData = async (selectedRows,role) => {
    console.log("selectedRows2",selectedRows,role);
   let response;
   try{
      if(role==="assignToDeveloper"){
        response=await axios.put(`${process.env.REACT_APP_URL}/api/assignobjects/developerupdate/`, selectedRows, { withCredentials: true })
    }
    if(role==="assignToTester"){
      if(assignObjectFlag==="source"){
      response=await axios.put(`${process.env.REACT_APP_URL}/api/assignobjects/testerupdatesource/`, selectedRows, { withCredentials: true })
      }
      if(assignObjectFlag==="target"){
        response=await axios.put(`${process.env.REACT_APP_URL}/api/assignobjects/testerupdatetarget/`, selectedRows, { withCredentials: true })
        }
    }
    setFlag(false);
    setColor("green");
    setTitle("Success");
    function refreshText(){
      if(role==="assignToDeveloper"){
        setText("Successfully assigned to Developer");
        }else{
          setText("Successfully assigned to Tester");
        }
    }
  
   
 await refreshText();
buttonRef.current.click();
  }
  catch(err){
  
    setColor("red");
         setTitle("Error");
console.log(" catch(err){",err)
       buttonRef.current.click();

  }
   
  }
   
 
     
  
  // }, [selectedRows]);
  let dbData = JSON.parse(localStorage.getItem("dbData"));
  const handleExportRows = (rows) => {
    console.log(rows.map((row) => row.original));
  };
  const assigntoUser = async(rows,e) => {

    const updatedRow=rows.map((row) => row.original);
    // console.log(rows.map((row) => row.original),e.target.value,e.target.name);
    for(let i=0;i<updatedRow.length;i++){
      updatedRow[i][e.target.name]=e.target.value;
      updatedRow[i].role=e.target.value;
    }
    console.log("updatedRow3",updatedRow);
    const handleChange = () => {
      setSelectedRows(updatedRow);
    }
    
      await handleChange();
    

     console.log("updatedRow4",updatedRow);   
 updateData(updatedRow,e.target.name);
  };
  
  const handleExportData = () => {
    // csvExporter.generateCsv(data);
  };

    return (
    <>
      <NotificationReact
      buttonRef={buttonRef}
      title={title}
      color={color}
      text={text}
      />
    <br/><br/><br/>
    <MaterialReactTable
      columns={columns}
      data={tableData}
      enableRowSelection
      positionToolbarAlertBanner="bottom"
      renderTopToolbarCustomActions={({ table }) => (

  <>
   <label style={{fontSize:"17px",whiteSpace: 'pre-line'}}>Project Id:{dbData.Project_Id} Project Name:{dbData.Project_Name}</label>
   {(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected ) && (
    <>

  <br/>
  
 {/* { schemas.map((result)=>(<option  value={result.schema_name} >{result.schema_name}</option>))} */}
 {(assignObjectFlag==="source" ) && (
    <select  className="form-select  assignSelect" aria-label="Default select example" value= {{label:"Assign To Developer", value:""}} name="assignToDeveloper" onChange={(e) => assigntoUser(table.getSelectedRowModel().rows,e)} >
 <option style={{display:"none"}} selected>Assign To Developer</option>
 { developerData.map((result)=>(<option  value={result.user_id} >{result.username}</option>))}
  </select>
  )}
 
  <select  className="form-select  assignSelect" aria-label="Default select example " value= {{label:"Assign To Tester", value:""}} name="assignToTester" onChange={(e) => assigntoUser(table.getSelectedRowModel().rows,e)} >
  <option style={{display:"none"}} selected>Assign To Tester</option>
  { testerData.map((result)=>(<option  value={result.user_id} >{result.username}</option>))}
 {/* { schemas.map((result)=>(<option  value={result.schema_name} >{result.schema_name}</option>))} */}

  </select>
  </>
)}
  </>
  
      )}
    />
    </>
  );
};

export default AssignObject;
