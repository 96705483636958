import axios from "axios";
import React, {useRef, useState,useEffect } from "react";
import { useContext } from "react";
import { Link} from "react-router-dom";
import { AuthContext } from "../context/authContext";
import NotificationReact from "./NotificationReact";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";

const AddProject = () => {
  const buttonRef = useRef(null); 
 function refreshText(){
    setText("");
    }
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("");
  const [text, setText] = useState(""); 
  useEffect(() => {
    console.log(" useEffect(() =>( {");
    if(text!==""){
    buttonRef.current.click();
    }

  }, [text]);
  const [ip, setIp] = useState(null);
  const [projects, setProjects] = useState([]);
 
  const [inputs, setInputs] = useState({Migration_Type:"partial"  
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_URL}/api/posts/ProjectName`, { withCredentials: true });
        setProjects(res.data);
        console.log("phjg22",res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();

  }, []);

  const [err, setError] = useState(null);



  


  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${process.env.REACT_APP_URL}/api/posts/`, inputs, { withCredentials: true });
     
//toast("project added");
      setColor("green");
           setTitle("Success");
       
         
              await refreshText();
           setText(() =>("project added")); 
      // navigate("/");
    } catch (err) {
      setColor("red");
      setTitle("Error");
   if(err.response.data.sqlMessage){
      await refreshText();
    setText(() =>( err.response.data.sqlMessage));
 
  
  }
      else{
        setText(() =>( ""));
        setText(() =>( err.response));
     

      }
     
//toast("error occured");
      setColor("red");
      setTitle("Error");
 setText(err.response.data);
    }
   
    setError(null);
  };
  return (
    <div>
    <div  >
    
          <div id = "frm"    >
            <h3>Add Project</h3> <br/>
         
    <form method="post" action="" onSubmit={handleSubmit}   >
    <div class="row">
    <div class="col">
    <div className="form-element">
    <label>Client Name	:</label>
    <input required type="text" className="form-control" name="Client_Name" onChange={handleChange}/>
    </div><br/>
        <label>Source Database:</label>
    {/* <select required class="form-select" aria-label="Default select example" name="Source_Database" onChange={handleChange} style={{fontSize: "1.98rem"}}> */}
    <select required class="form-select" aria-label="Default select example" name="Source_Database" onChange={handleChange} >
    <option selected value="" disabled>Source Database</option>
    { projects.map((result)=>(<option  value={result.id} >{result.dbname}</option>))}
   </select><br/>
   
    <label>Target Database:</label>
    <select required class="form-select" aria-label="Default select example" name="Target_Database" onChange={handleChange}>
    <option selected value="" disabled>Target Database</option>
    { projects.map((result)=>(<option  value={result.id} >{result.dbname}</option>))}
    </select><br/>
    
    <label>Migration Type	:</label>
    <select required class="form-select" aria-label="Default select example" name="Migration_Type" onChange={handleChange}>
    <option selected value="" disabled>Migration Type</option>
  <option value="partial">Partial</option>
  <option value="complete">Complete</option>
  </select><br/>
    <div className="form-element">
    <label>Project Name:</label>
    <input required type="text" className="form-control" name="Project_Name" onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Planned Start Date:</label>
    <input required type="date" className="form-control" name="Planned_Start_Date" onChange={handleChange}/>
    </div><br/>
   
    <div className="form-element">
    <label>Planned End Date:</label>
    <input required type="date" className="form-control" name="Planned_End_Date" onChange={handleChange}/>
    </div><br/>
     <div className="form-element">
    <label>Actual Start Date:</label>
    <input required type="date" className="form-control" name="Actual_Start_Date" onChange={handleChange}/>
    </div><br/> 
    <div className="form-element">
    <label>Actual_End_Date:</label>
    <input required type="date" className="form-control" name="Actual_End_Date" onChange={handleChange}/>
    </div><br/>
    
    <label>Project Status:</label>
     <select required class="form-select" aria-label="Default select example" name="Project_Status" onChange={handleChange}>
     <option selected value="" disabled>Project Status</option>
  <option value="pending">Pending</option>
  <option value="completed">Completed</option>
  <option value="inProgress">In Progress</option>
  </select><br/>
   
<br/>
</div>
<div class="col">
<div className="form-element">
    <label>Source IP:</label>
    <input required type="text" className="form-control" name="source_ip" onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Target IP:</label>
    <input required type="text" className="form-control" name="target_ip" onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Source Port:</label>
    <input required type="number" className="form-control" name="source_port" onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Source DB Name:</label>
    <input required type="text" className="form-control" name="source_db_name" onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Source Usename:</label>
    <input required type="text" className="form-control" name="source_username" onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Source Password:</label>
    <input required type="password" className="form-control" name="source_db_password" onChange={handleChange}/>
    </div><br/>

    <div className="form-element">
    <label>Target Port:</label>
    <input required type="number" className="form-control" name="target_port" onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Target DB Name:</label>
    <input required type="text" className="form-control" name="target_db_name" onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Target Usename:</label>
    <input required type="text" className="form-control" name="target_username" onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Target Password:</label>
    <input required type="password" className="form-control" name="target_db_password" onChange={handleChange}/>
    </div><br/>
    </div>
    
     </div>   
     <input type="submit" className="btn btn-success"  value="Add project" style={{width:"100%"}}/>

</form>
</div> 
</div>
<NotificationReact
      buttonRef={buttonRef}
      title={title}
      color={color}
      text={text}
      />
<ToastContainer />
    </div>
  );
};

export default AddProject;
{/* <span>
Don't you have an account? <Link to="/register">Register</Link>
</span> */}
