import axios from "axios";
import React, {useRef, useState,useEffect } from "react";
import { useContext,useMemo } from "react";
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { Link } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotificationReact from "./NotificationReact";

const DBConnect = () => {
  const buttonRef = useRef(null); 
 function refreshText(){
    setText("");
    }
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("");
  const [text, setText] = useState(""); 
  useEffect(() => {
    if(text!==""){
    buttonRef.current.click();
    }

  }, [text]);
  const columnswithtables = useMemo(
    () => [
      {
        accessorKey: 'table_name', //access nested data with dot notation
        header: 'Table Name',
      },
  
      {
        accessorKey: 'column_name', //access nested data with dot notation
        header: 'Column Name',
      },
      {
        accessorKey: 'data_type', //access nested data with dot notation
        header: 'Data Type',
      },
      {
        accessorKey: 'default_value', //access nested data with dot notation
        header: 'Default Value',
      },

      {
        accessorKey: 'max_length',
        header: 'Max Length',
      },
      {
        accessorKey: 'is_nullable',
        header: 'Is Nullable?',
      },
      
    
    ],
    [],
  );
  const columnTables = useMemo(
    () => [
      {
        accessorKey: 'table_name', //access nested data with dot notation
        header: 'Object Name',
      },
      {
        accessorKey: 'schema', //access nested data with dot notation
        header: 'Schema Name',
      },
      {
        accessorKey: 'object_type_source', //access nested data with dot notation
        header: 'Object Type',
      },
    
    ],
    [],
  );
  const columnFunctions = useMemo(
    () => [
    
      {
        accessorKey: 'table_name', //access nested data with dot notation
        header: 'Object Name',
      },
      {
        accessorKey: 'schema', //access nested data with dot notation
        header: 'Schema Name',
      },
      {
        accessorKey: 'object_type_source', //access nested data with dot notation
        header: 'Object Type',
      },
    ],
    [],
  );
  const columnViews = useMemo(
    () => [
    
      {
        accessorKey: 'table_name', //access nested data with dot notation
        header: 'Object Name',
      },
      {
        accessorKey: 'schema', //access nested data with dot notation
        header: 'Schema Name',
      },
      {
        accessorKey: 'object_type_source', //access nested data with dot notation
        header: 'Object Type',
      },
    ],
    [],
  );
 
  let dbData = JSON.parse(localStorage.getItem("dbData"));
  dbData.object_type_source= 'tables';
  dbData.schema= '';
  dbData.schemaTarget= '';
  const [inputs, setInputs] = useState(dbData);
  const [sourceStatus, setSourceStatus] = useState(false);
  const [targetStatus, setTargetStatus] = useState(false);
  const [databaseName, setDatabseName] = useState([]);
  const [projects, setProjects] = useState([]);
  const [schemas, setSchemas] = useState([]);
  const [schemasTarget, setSchemasTarget] = useState([]);
  const [queryData, setQueryData] = useState([]);
  const [importFlag, setImportFlag] = useState(false);
  const [err, setError] = useState(null);

  // let dbflag = JSON.parse(localStorage.getItem("dbflag"));
  // if(dbflag==="yes"){
 
  // }
  console.log("querydata33",queryData);

  
 

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_URL}/api/posts/ProjectName`, { withCredentials: true });
        setProjects(res.data);
       } catch (err) {
        console.log(err);
      }
    };
    fetchData();
 

  }, []);
  useEffect(() => {
    const fetchSchema = async () => {
     
        if(dbData && dbData.Source_Database==='1'){
          try {
            const res = await axios.post(`${process.env.REACT_APP_URL}/api/dbconnect/getschema/`, dbData, { withCredentials: true });  
            setSourceStatus(true); 
            console.log("fetchSchema1",res);
        let arr1=[];
        for(let i=0;i<Object.keys(res.data.recordsets).length;i++){
          res.data.recordsets[i].schema_name=res.data.recordsets[0][i].name;
          arr1.push(res.data.recordsets[i]);
        }
      
        console.log("arr1,",arr1);
        setSchemas(arr1);
      } catch (err) {
        console.log(err);
              await refreshText();
           setText(() =>"");
//toast(err);
    
        setColor("red");
        setTitle("Error");
   setText(err.response.data||err);

      }
        }
        if(dbData && dbData.Source_Database==='3'){
         
          try {
            console.log("fetchSchema3");  
            // const res = await axios.post(`${process.env.REACT_APP_URL}/api/dbconnect/getschema/`, dbData);  
            const res = await axios.post(`${process.env.REACT_APP_URL}/api/dbconnect/getschema/`, dbData, { withCredentials: true });  
            setSourceStatus(true);   
            console.log("fetchSchema4");   
        let arr1=[];
        for(let i=0;i<Object.keys(res.data).length;i++){
          arr1.push(res.data[i]);
        }
       
        setSchemas(arr1);
      } catch (err) {
        console.log(err);
              await refreshText();
           setText(() =>"");
//toast(err);
        setColor("red");
        setTitle("Error");
   setText(err.response.data||err);
      }
    }
    };
    fetchSchema();


    const fetchTargetSchema = async () => {
      console.log("fetchTargetSchema2",dbData);
      if(dbData && dbData.Target_Database==='1'){
        try {
          const res = await axios.post(`${process.env.REACT_APP_URL}/api/dbconnect/gettargetschema/`, dbData, { withCredentials: true });  
          setTargetStatus(true); 
          console.log("fetchTargetSchema",res);
      let arr1=[];
      for(let i=0;i<Object.keys(res.data.recordsets).length;i++){
        res.data.recordsets[i].schema_name=res.data.recordsets[0][i].name;
        arr1.push(res.data.recordsets[i]);
      }
    
      console.log("arr1,",arr1);
      setSchemasTarget(arr1);
    } catch (err) {
      console.log(err);
            await refreshText();
           setText(() =>"");
//toast(err);
      setColor("red");
      setTitle("Error");
 setText(err.response.data||err);
    }
      }
      if(dbData && dbData.Target_Database==='3'){
        try {
          const res = await axios.post(`${process.env.REACT_APP_URL}/api/dbconnect/gettargetschema/`, dbData, { withCredentials: true });       
          setTargetStatus(true); 
      let arr1=[];
      for(let i=0;i<Object.keys(res.data).length;i++){
        arr1.push(res.data[i]);
      }
    
      setSchemasTarget(arr1);
    } catch (err) {
      console.log(err);
            await refreshText();
           setText(() =>"");
//toast(err);
      setColor("red");
      setTitle("Error");
 setText(err.response.data||err);
    }
  }
  };
  fetchTargetSchema();

  }, []);
  

 
  
  const handleChange = (e) => {
    dbData[e.target.name]=e.target.value;
    console.log("e.target.name, e.target.value37",e.target.name, e.target.value,dbData);
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    
 

  };
  const importSource = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_URL}/api/dbconnect/importsource`, queryData, { withCredentials: true });
            await refreshText();
           setText(() =>"");
//toast("Import into mysql database Suucessfull!!");
  
      setColor("green");
      setTitle("Success");
      await refreshText();
           setText(() =>"Import into mysql database Suucessfull!!");
      // navigate("/");
    } catch (err) {
      
           setText(() =>"");
//toast(err.response.data);
      setColor("red");
           setTitle("Error");
      setText(err.response.data);
    }
   
    setError(null);
  }
  const importTarget = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_URL}/api/dbconnect/importtarget`, queryData, { withCredentials: true });
            await refreshText();
           setText(() =>"");
//toast("Imported into target table successfully!!!");
  
      setColor("green");
      setTitle("Success");
      await refreshText();
           setText(() =>"Imported into target table successfully!!!");
      // navigate("/");
    } catch (err) {
      setError(err.response.data);
            await refreshText();
           setText(() =>"");
//toast(err.response.data);
      setColor("red");
      setTitle("Error");
 setText(err.response.data);
    }
   
    setError(null);

  }
  const   generateTargetReport  = async () => {
    // setInputs((prev) => ({ ...prev, reportType: "target" }));

      inputs.reportType= "target";
      handleSubmitTarget();
  
  }
  const   generateSourceReport  = async () => {
    // setInputs((prev) => ({ ...prev, reportType: "source" }));
    inputs.reportType= "source";
     handleSubmit();
  }
  const handleSubmitTarget = async (e) => {

    if(inputs.schema==='')
    {
      if(schemas.length!==0){
        inputs.schema=schemas[0].schema_name;
      }
    }
    if(inputs.schemaTarget==='')
    {
      if(schemasTarget.length!==0){
        inputs.schemaTarget=schemasTarget[0].schema_name;
      }
    }
    // e.preventDefault();
    try {
      const res = await axios.post(`${process.env.REACT_APP_URL}/api/dbconnect/`, inputs, { withCredentials: true });
 console.log("res4",res);
            await refreshText();
           setText(() =>"");
//toast("");
      setColor("green");
           setTitle("Success");
           await refreshText();
           setText(() =>"task successfull");
      let length=0;
      let data=[];
     if(dbData.Target_Database==='1' ){
   
       data=[...res.data.recordsets[0]];
       length=Object.keys(data).length;
       for (let i=0;i<length;i++){
        data[i].schema=inputs.schemaTarget;
        data[i].object_type_source=inputs.object_type_source;
        if(inputs.object_type_source === "tables"){
          data[i].object_type_source_id=1; 
        }
        if(inputs.object_type_source === "views"){
          data[i].object_type_source_id=2; 
        }
        if(inputs.object_type_source === "functions"){
          data[i].object_type_source_id=3; 
          data[i].table_name=data[i].routine_name;
        }
        if(inputs.object_type_source === "procedures"){
          data[i].object_type_source_id=4; 
          data[i].table_name=data[i].routine_name;
          }
        data[i].Project_Id=dbData.Project_Id;
               }
     }
     if(dbData.Target_Database==='3' ){
      
       data=[...res.data];
       length=data.length;
       for (let i=0;i<length;i++){
        data[i].schema=inputs.schemaTarget;
        data[i].object_type_source=inputs.object_type_source;
        if(inputs.object_type_source === "tables"){
          data[i].object_type_source_id=1; 
        }
        if(inputs.object_type_source === "views"){
          data[i].object_type_source_id=2; 
        }
        if(inputs.object_type_source === "functions"){
          data[i].object_type_source_id=3; 
          data[i].table_name=data[i].routine_name;
        }
        if(inputs.object_type_source === "procedures"){
          data[i].object_type_source_id=4; 
          data[i].table_name=data[i].routine_name;
          }
        data[i].Project_Id=dbData.Project_Id;
               }
     }

     
      setImportFlag(true);
      setQueryData(data);
      console.log("v42",data[0],typeof data,length);
      // navigate("/");
    } catch (err) {
      setError(err);
            await refreshText();
           setText(() =>"");
//toast(err);
      setColor("red");
      setTitle("Error");
 setText(err.response.data||err);
    }
   
    setError(null);
  };
  const handleSubmit = async (e) => {

    if(inputs.schema==='')
    {
      if(schemas.length!==0){
        inputs.schema=schemas[0].schema_name;
      }
    }
    if(inputs.schemaTarget==='')
    {
      if(schemasTarget.length!==0){
        inputs.schemaTarget=schemasTarget[0].schema_name;
      }
    }
    // e.preventDefault();
    try {
      const res = await axios.post(`${process.env.REACT_APP_URL}/api/dbconnect/`, inputs, { withCredentials: true });
 console.log("res4",res);
            await refreshText();
           setText(() =>"");
//toast("");
      setColor("green");
      setTitle("Success");
      await refreshText();
           setText(() =>"task successfull");
      let length=0;
      let data=[];
     if(dbData.Source_Database==='1' ){
   
       data=[...res.data.recordsets[0]];
       length=Object.keys(data).length;
       for (let i=0;i<length;i++){
        data[i].schema=inputs.schema;
        data[i].object_type_source=inputs.object_type_source;
        if(inputs.object_type_source === "tables"){
          data[i].object_type_source_id=1; 
        }
        if(inputs.object_type_source === "views"){
          data[i].object_type_source_id=2; 
        }
        if(inputs.object_type_source === "functions"){
          data[i].object_type_source_id=3; 
          data[i].table_name=data[i].routine_name;
        }
        if(inputs.object_type_source === "procedures"){
          data[i].object_type_source_id=4; 
          data[i].table_name=data[i].routine_name;
          }
        data[i].Project_Id=dbData.Project_Id;
               }
     }
     if(dbData.Source_Database==='3' ){
      
       data=[...res.data];
       length=data.length;
       for (let i=0;i<length;i++){
        data[i].schema=inputs.schema;
        data[i].object_type_source=inputs.object_type_source;
        if(inputs.object_type_source === "tables"){
          data[i].object_type_source_id=1; 
        }
        if(inputs.object_type_source === "views"){
          data[i].object_type_source_id=2; 
        }
        if(inputs.object_type_source === "functions"){
          data[i].object_type_source_id=3; 
          data[i].table_name=data[i].routine_name;
        }
        if(inputs.object_type_source === "procedures"){
          data[i].object_type_source_id=4; 
          data[i].table_name=data[i].routine_name;
          }
        data[i].Project_Id=dbData.Project_Id;
               }
     }

     
      setImportFlag(true);
      setQueryData(data);
      console.log("v41",data[0],typeof data,length);
      // navigate("/");
    } catch (err) {
      setError(err);
            await refreshText();
           setText(() =>"");
//toast(err);
      setColor("red");
           setTitle("Error");
      setText(err.response.data||err);
    }
   
    setError(null);
  };
  return (
    <div style={{padding:"17px"}}>
    <>
    <div>
    <div  >
      
          <div id = "frmDB"  >
            <h3>Welcome to DB Migration Utility</h3> <br/>
         
 
   
    <div class="row">
    <div class="col">
        <label>Source Database:</label>
    <input required type="text" className="form-control" value={dbData.Source_Name || ""} name="Source_Database:" placeholder="server.pascalinesoft.com"  onChange={handleChange}/>
    <br/>
    <div className="form-element">
    <label>Source DB IP:</label>
    <input required type="text" className="form-control" value={dbData.source_db_ip || ""} name="Source_DB_IP" placeholder="server.pascalinesoft.com"  onChange={handleChange}/>
    </div><br/>
    
      <>
      <div className="form-element">
    <label>Source DB Name:</label>
    <input required type="text" className="form-control" value={dbData.source_db_name || ""} name="Source_DB_Name" placeholder="server.pascalinesoft.com"  onChange={handleChange}/>
    <br/></div>
    </>
 
   {(schemas.length !== 0) && (
      <>
    <label>Schema:</label>
    <select  class="form-select" aria-label="Default select example" name="schema" onChange={handleChange}>
   { schemas.map((result)=>(<option  value={result.schema_name} >{result.schema_name}</option>))}
    </select><br/>
    </>
    )}
    <div className="form-element">
    <label>Source DB Port:</label>
    <input required type="text" className="form-control" name="Source_DB_Port" value={dbData.source_db_port} onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Source DB Username:</label>
    <input  type="text" className="form-control" name="Source_DB_Username" value={dbData.source_db_username || ""} placeholder="musab" onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Source DB Password:</label>
    <input required type="password" className="form-control" value={dbData.source_db_password || ""} name="Source_DB_Password" onChange={handleChange}/>
    </div><br/>
    <label>Object Type:</label>
<select required class="form-select" aria-label="Default select example" name="object_type_source" onChange={handleChange}>
  <option value="tables">Tables</option>
  <option value="views">Views</option>
 
  <option value="functions">Functions</option>
  <option value="procedures">Procedures</option>
  </select><br/>
  {(sourceStatus === false) && (
 

  <button  className="btn btn-success"  onClick={generateSourceReport} disabled> <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />Loading................</button>
        )}
          {(sourceStatus === true) && (
            <button  className="btn btn-success"  onClick={generateSourceReport} > Generate Source Report</button>
          )}
    </div>
   
    <div class="col">
    <div className="form-element">
    <label>Target Database:</label>
    <input  type="text" className="form-control" name="Target_DB_IP"  value={dbData.Target_Name || ""} onChange={handleChange}/>
    </div>
  <br/>
    <div className="form-element">
    <label>Target DB IP:</label>
    <input  type="text" className="form-control" name="Target_DB_IP"  value={dbData.target_db_ip || ""} onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Target DB Name:</label>
    <input  type="text" className="form-control" name="Target_DB_Name"  value={dbData.target_db_name || ""} onChange={handleChange}/>
    </div><br/>
    {(schemasTarget.length !== 0) && (
      <>
    <label>Schema:</label>
    <select  class="form-select" aria-label="Default select example" name="schemaTarget" onChange={handleChange}>
   { schemasTarget.map((result)=>(<option  value={result.schema_name} >{result.schema_name}</option>))}
    </select><br/>
    </>
    )}
    <div className="form-element">
    <label>Target DB Port:</label>
    <input  type="text" className="form-control" name="Target_DB_Port" value={dbData.target_db_port || ""} onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Target DB Username:</label>
    <input  type="text" className="form-control" name="Target_DB_Username" value={dbData.target_db_username || ""} onChange={handleChange}/>
    </div><br/>
    <div className="form-element">
    <label>Target DB Password:</label>
    <input  type="password" className="form-control" name="Target_DB_Password" value={dbData.target_db_password || ""} onChange={handleChange}/>
    </div><br/>
    <label>Object Type:</label>
<select class="form-select" aria-label="Default select example"  name="object_type_source" onChange={handleChange}>
  <option value="tables">Tables</option>
  <option value="views">Views</option>

  <option value="functions">Functions</option>
  <option value="procedures">Procedures</option>
  </select><br/>
  {(targetStatus === false) && (
  <button  className="btn btn-success"  onClick={generateTargetReport} disabled><Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />Loading................</button>
  )}
    {(targetStatus === true) && (
  <button  className="btn btn-success"  onClick={generateTargetReport} >Generate Target Report</button>
  )}
</div>
  </div>

        


</div> 
</div>
{(importFlag === true) && (
      <>
      <br/>
      <button  className="btn btn-success"  onClick={importSource} style={{width:"10%"}}>Import Source</button>
<button  className="btn btn-success"  onClick={importTarget} style={{width:"10%",position: "relative",
    left: "80%"}}>Import Target</button>
      </>
)}
<br/><br/>
<NotificationReact
      buttonRef={buttonRef}
      title={title}
      color={color}
      text={text}
      />
{(inputs.object_type_source === "tables") && (
      <>
<MaterialReactTable columns={columnTables} data={queryData} />
</>
    )}
    {(inputs.object_type_source === "views") && (
      <>
<MaterialReactTable columns={columnViews} data={queryData} />
</>
    )}
    {(inputs.object_type_source === "functions") && (
      <>
<MaterialReactTable columns={columnFunctions} data={queryData} />
</>
    )}
        {(inputs.object_type_source === "procedures") && (
      <>
<MaterialReactTable columns={columnFunctions} data={queryData} />
</>
    )}
<ToastContainer />

    </div>
  
 </>
 </div>
  );
};

export default DBConnect;
{/* <span>
Don't you have an account? <Link to="/register">Register</Link>
    {(databaseName.length !== 0) && (
      <>
    <label>Source DB Name:</label>
    <select  class="form-select" aria-label="Default select example" value={dbData.source_db_name} name="Source_DB_Name" onChange={handleChange}>
   { databaseName.map((result)=>(<option  value={result.database_name} >{result.database_name}</option>))}
    </select><br/>

       <select required class="form-select" aria-label="Default select example" name="sourceDatabase" onChange={handleChange}>

   { projects.map((result)=>(<option  value={result.id} >{result.dbname}</option>))}
    </select><br/>
    </>
    )}
</span> */}
