import axios from "axios";
import React, {useRef, useState,useEffect } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/authContext";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import { AlignHorizontalCenter } from "@mui/icons-material";
import NotificationReact from "./NotificationReact";
const AssignProject = () => {
  const buttonRef = useRef(null); 
 function refreshText(){
    setText("");
    }
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("");
  const [text, setText] = useState(""); 
  useEffect(() => {
    if(text!==""){
    buttonRef.current.click();
    }

  }, [text]);
  

 
  const [inputs, setInputs] = useState({ Project_Id:"", role:"developer",user_id:""});
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [err, setError] = useState(null);


  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_URL}/api/adminresults/`, { withCredentials: true });
        setProjects(res.data);
        console.log("phjg",res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();

  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_URL}/api/assigned/getuserz`, { withCredentials: true });
        setUsers(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
    
  }, []);
  
  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    console.log("inputs43",inputs,e.target.name,e.target.value)
  };

  const handleSubmit = async (e) => {
    console.log("inputs45",inputs)
    // if(inputs.Project_Id!==null){
    //   console.log("c"); 
    //   if(projects.length!==0){
    //     console.log("b"); 
    //     inputs.Project_Id=projects[0].Project_Id;
    //   }
    // }
    // if(inputs.user_id===""){
    //   if(users.length!==0){
    //     inputs.user_id=users[0].user_id;
    //   }
    // }
    e.preventDefault();
    try {
      const res = await axios.post(`${process.env.REACT_APP_URL}/api/assigned/`, inputs, { withCredentials: true });
            await refreshText();
           setText(() =>"");
//toast("project assigned");
         setColor("green");
      setTitle("Success");
 setText(res.data);
   
   
    } catch (err) {
      setError(err.response.data);
            await refreshText();
           setText(() =>"");
//toast(err.response.data);
          setColor("red");
      setTitle("Error");
 setText(err.response.data);
      
    }
   
    setError(null);
  };
    return (
    <>
      <NotificationReact
      buttonRef={buttonRef}
      title={title}
      color={color}
      text={text}
      />

    <div>
    <div  >
     
          <div id = "frm" style={{width: "30%",    marginLeft: "31%"}} >
            <h3>Assign Project</h3> <br/>
       
            
    <form method="post" action="" onSubmit={handleSubmit}>
   

        <label>User Name:</label>
    <select required class="form-select" aria-label="Default select example" name="user_id" onChange={handleChange}>
   { users.map((result)=>(<option  value={result.user_id} >{result.user_id}--{result.username}</option>))}
    </select><br/>
    {(projects.length !== 0) && (
      <>
<label>Project Name:</label>
    <select required class="form-select" aria-label="Default select example" name="Project_Id" onChange={handleChange}>
    {   projects.map((result)=>(<option  value={result.Project_Id} >{result.Project_Id}--{result.Project_Name}</option>))}
    </select><br/>
   </>
    )}
<label>Role:</label>
    <select required class="form-select" aria-label="Default select example" name="role" onChange={handleChange}>
    <option selected="selected" value="developer" >Developer</option>
    <option  value="tester" >Tester</option>
    </select><br/>
     <input type="submit" className="btn btn-success"  value="Assign project" style={{width:"100%" }}/>
   
<br/>

        
</form>
</div> 
</div>

<ToastContainer />

    </div>

 </>
  );
};

export default AssignProject;
{/* <span>
Don't you have an account? <Link to="/register">Register</Link>
</span> */}
