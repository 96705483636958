import React from "react";
import { useState ,useRef,useEffect} from "react";
import axios from "axios";
import NotificationReact from "./NotificationReact";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Register = () => {
  const buttonRef = useRef(null); 
 function refreshText(){
    setText("");
    }
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("");
  const [text, setText] = useState(""); 
  useEffect(() => {
    if(text!==""){
    buttonRef.current.click();
    }

  }, [text]);
  const [inputs, setInputs] = useState({
    username: "",
    email: "",
    password: "",
    create_date: new Date(),
    is_admin:0
  });
  const [err, setError] = useState(null);



  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_URL}/api/auth/register`, inputs, { withCredentials: true });
            await refreshText();
           setText(() =>"");
//toast("user added!!!");
      setColor("green");
           setTitle("Success");
           await refreshText();
           setText(() =>"user added!!!");
      // window.location.replace('/login');
    } catch (err) {
      setError(err.response.data);
            await refreshText();
           setText(() =>"");
//toast(err.response.data);
      setColor("red");
      setTitle("Error");
 setText(err.response.data);

    }
  };

  return (

<div  style={{width: "30%",    marginLeft: "31%"}}>
     <NotificationReact
      buttonRef={buttonRef}
      title={title}
      color={color}
      text={text}
      />
         <h3>Add User</h3><br/>
    <div id = "frm"  >
  
     
<form method="post" action="" onSubmit={handleSubmit}>
<div className="form-element">
<label>Username:</label>
<input required type="text" className="form-control" name="username" onChange={handleChange}/>
</div><br/>

<div className="form-element">
<label>Password:</label>
<input required type="password" className="form-control" name="password" onChange={handleChange}/>
</div><br/>
<input type="submit" className="btn btn-success"  value="Add User" />
<br></br>
<p>
          {/* Do you have an account? <Link to="/login">Login</Link> */}
        </p>
</form>

</div> 
<ToastContainer />
</div>
  );
};

export default Register;
