/*!

=========================================================
* Migration Utility Application - v1.10.0
=========================================================

* Product Page: https://www.pascalinesoft.com/product/material-dashboard-react
* Copyright 2021 Saad Hassan Shah (https://www.pascalinesoft.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by saad hassan

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
/* eslint-disable */
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js";
import Maps from "views/Maps/Maps.js";
import NotificationsPage from "views/Notifications/Notifications.js";
import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";

// core components/views for RTL layout
import RTLPage from "views/RTLPage/RTLPage.js";
import HomePage from "pages/HomePage";
import DBConnect from "pages/DBConnect";
import AssignProject from "pages/AssignProject";
import AddProject from "pages/AddProject";
import Register from "pages/Register";

import Users from "pages/Users";
const user = JSON.parse(localStorage.getItem("user"));
// const cookies = new Cookies();
// if(user){
// cookies.set('access_token', user.token, { path: '/' });
// console.log(cookies.get('access_token')); // Pacman
// } console.log("document.cookie")
 
console.log("jwt3",user)
let dashboardRoutes;
if( user!==null && user.is_admin === 1){
 dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
 
  {
    path: "/homepage",
    name: "HomePage",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: HomePage,
    layout: "/admin",
  },
  {
    path: "/addproject",
    name: "Add Project",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: AddProject,
    layout: "/admin",
  },
  {
    path: "/assignproject",
    name: "Assign Project",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: AssignProject,
    layout: "/admin",
  },
  {
    path: "/adduser",
    name: "Add User",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: Register,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "View/Modify Users",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: Users,
    layout: "/admin",
  },
  // {
  //   path: "/table",
  //   name: "Table List",
  //   rtlName: "قائمة الجدول",
  //   icon: "content_paste",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography2",
  //   rtlName: "طباعة",
  //   icon: LibraryBooks,
  //   component: Typography,
  //   layout: "/admin",
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   rtlName: "الرموز",
  //   icon: BubbleChart,
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   rtlName: "خرائط",
  //   icon: LocationOn,
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   rtlName: "إخطارات",
  //   icon: Notifications,
  //   component: NotificationsPage,
  //   layout: "/admin",
  // },
  // {
  //   path: "/rtl-page",
  //   name: "RTL Support",
  //   rtlName: "پشتیبانی از راست به چپ",
  //   icon: Language,
  //   component: RTLPage,
  //   layout: "/rtl",
  // },
  // {
  //   path: "/dbconnect",
  //   name: "",
  //   rtlName: "پشتیبانی از راست به چپ",
  //   icon: Language,
  //   component: DBConnect,
  //   layout: "/admin",
    
  // },
  // {
  //   path: "/upgrade-to-pro",
  //   name: "Upgrade To PRO",
  //   rtlName: "التطور للاحترافية",
  //   icon: Unarchive,
  //   component: UpgradeToPro,
  //   layout: "/admin",
  // },
];
}
else{
   dashboardRoutes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      rtlName: "لوحة القيادة",
      icon: Dashboard,
      component: DashboardPage,
      layout: "/admin",
    },
   
    {
      path: "/homepage",
      name: "HomePage",
      rtlName: "ملف تعريفي للمستخدم",
      icon: Person,
      component: HomePage,
      layout: "/admin",
    },
  ]
  }
export default dashboardRoutes;
