import React, {useRef, useCallback, useMemo, useState,useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import NotificationReact from './NotificationReact';
import axios from "axios";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  
  
} from '@mui/material';
import { Delete, Edit ,Inventory, Label} from '@mui/icons-material';

import { Link, useLocation } from "react-router-dom";
const AdminResults = () => {
  const buttonRef = useRef(null); 
 function refreshText(){
    setText("");
    }
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("");
  const [text, setText] = useState(""); 
  useEffect(() => {
    if(text!==""){
    buttonRef.current.click();
    }

  }, [text]);

  
  let dbData = JSON.parse(localStorage.getItem("dbData"));
      const [posts, setPosts] = useState([]);
  
   
      const cat = useLocation().search
      var user = JSON.parse(localStorage.getItem("user"));
      console.log("loc",user,typeof user);
   
     
    
      let tableFlag = (localStorage.getItem("tableflag"));
      console.log("tableFlag",tableFlag,typeof tableFlag);
  

      const [flag, setFlag] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [index, setIndex] = useState(-2);
  useEffect(() => {
    const fetchData = async () => {
      try {
         let res;  
      if(tableFlag==="source"){
         res = await axios.get(`${process.env.REACT_APP_URL}/api/adminresults/source/${dbData.Project_Id}`, { withCredentials: true });
      }
      if(tableFlag==="target"){
         res = await axios.get(`${process.env.REACT_APP_URL}/api/adminresults/target/${dbData.Project_Id}`, { withCredentials: true });
      }
        let data=[...res.data];
        console.log("dbData",dbData);
        for (let i=0;i<data.length;i++){
          data[i].Project_Name=dbData.Project_Name;
                    data[i].user_id=user.user_id;
                    if(data[i].object_type_id === 1){
                     
                      data[i].object_type="tables"; 
                    }
                    if(data[i].object_type_id === 2){
                 
                      data[i].object_type="views"; 
                    }
                    if(data[i].object_type_id === 3){
                   
                      data[i].object_type="functions"; 
                              }
                    if(data[i].object_type_id === 4){
                     
                      data[i].object_type="procedures";
                  }
        }
        setTableData(data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
   
  }, []);

  useEffect(() => {
    console.log("tableData94",tableData);
  
 
  }, [tableData]);
  useEffect(() => {
    const updateData = async () => {
      if(flag===true){
       
        if(tableFlag==="source"){
      
        }
      }
  };
  
 updateData();
  }, [tableData]);
  const handleCreateNewRow = (values) => {
    tableData.push(values);
    setTableData([...tableData]);
  };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      console.log("row.index,values,row,tableData[row.index]5",row.index,values,row,tableData[row.index])
    
      
      // tableData[row.index]=values;
      await setIndex(row.index);
      setFlag(true);
      //send/receive api updates here, then refetch or update local table data for re-render
      setTableData([...tableData]);
    
    
      exitEditingMode(); //required to exit editing mode and close modal
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    // (row) => {
    //   if (
    //     !confirm(`Are you sure you want to delete ${row.getValue('firstName')}`)
    //   ) {
    //     return;
    //   }
    //   //send api delete request here, then refetch or update local table data for re-render
    //   tableData.splice(row.index, 1);
    //   setTableData([...tableData]);
    // },
    // [tableData],
    (row) => {
       
    localStorage.setItem("dbData", JSON.stringify(posts[row.index]));
    localStorage.setItem("dbflag", 'yes'); 

    },
    [posts],
  );

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
   
      };
    },
    [validationErrors],
  );

  const columns = useMemo(
    () => [
      // {
      //   accessorKey: 'project_id',
      //   header: 'Project Id',
      //   enableColumnOrdering: false,
      //   enableEditing: false, //disable editing on this column
      //   enableSorting: false,
      //   //size: 20,
      // },
      // {
      //   accessorKey: 'Project_Name',
      //   header: 'Project Name',
      //   enableEditing: false,
      //   //size: 40,
      //   muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
      //     ...getCommonEditTextFieldProps(cell),
      //   }),
      // },
      {
        accessorKey: 'schema_name',
        header: 'Schema Name',
        enableEditing: false,
        //size: 40,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'object_name',
        header: 'Object Name',
        enableEditing: false,
        //size: 20,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
         
        }),
      },
      {
        accessorKey: 'object_type_id',
        header: 'object type id',
        enableEditing: false,
        //size: 20,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
         
        }),
      },
      {
        accessorKey: 'object_type',
        header: 'object type',
        enableEditing: false,
        //size: 20,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
         
        }),
      },
      // {
      //   accessorKey: 'username',
      //   header: 'Tested By',
      //   enableEditing: false,
      //   //size: 20,
      //   muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
      //     ...getCommonEditTextFieldProps(cell),
         
      //   }),
      // },
      {
        accessorKey: 'test_call',
        header: 'Test Call',
        //size: 20,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
         
        }),
      },
      {
        accessorKey: 'mm',
        header: 'Migrated By',
        //size: 20,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
         
        }),
      },
      {
        accessorKey: 'test_callmm',
        header: 'Tested By',
        //size: 20,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
         
        }),
      },
      {
        accessorKey: 'test_result',
        header: 'Test Result',
        //size: 20,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
         
        }),
      },
   
    //   {
    //     accessorKey: 'state',
    //     header: 'State',
    //     muiTableBodyCellEditTextFieldProps: {
    //       select: true, //change to select for a dropdown
    //       children: states.map((state) => (
    //         <MenuItem key={state} value={state}>
    //           {state}
    //         </MenuItem>
    //       )),
    //     },
    //   },
    ],
    [getCommonEditTextFieldProps],
  );

    return (
    <>
      <NotificationReact
      buttonRef={buttonRef}
      title={title}
      color={color}
      text={text}
      />
       <div style={{padding:"17px"}}>
     <br/> 
   
      <MaterialReactTable
         renderTopToolbarCustomActions={() => (
          <>
        
        <h1>Project Id:{dbData.Project_Id} Project Name:{dbData.Project_Name}</h1>
          </>
        )}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            //size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal" //default
        enableColumnOrdering
        // enableEditing
        // onEditingRowSave={handleSaveRowEdits}
        // onEditingRowCancel={handleCancelRowEdits}
        
        // renderRowActions={({ row, table }) => (
        //   <Box sx={{ display: 'flex', gap: '5rem',marginLeft:"10%" }}>
           
        //   </Box>
        // )}
        // positionActionsColumn="last"
        // renderTopToolbarCustomActions={() => (
        //   <Button
        //     color="secondary"
        //     onClick={() => setCreateModalOpen(true)}
        //     variant="contained"
        //   >
        //     Create New Account
        //   </Button>
        // )}
        
      />
      <CreateNewAccountModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
      />
    </div></>
  );
};

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit }) => {
  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {}),
  );

  const handleSubmit = () => {
    //put your validation logic here
    onSubmit(values);
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Create New Account</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            {columns.map((column) => (
              <TextField
                key={column.accessorKey}
                label={column.header}
                name={column.accessorKey}
                onChange={(e) =>
                  setValues({ ...values, [e.target.name]: e.target.value })
                }
              />
            ))}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          Create New Account
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
const validateAge = (age) => age >= 18 && age <= 50;

export default AdminResults;